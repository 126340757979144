<template>
  <div>
    <div class="title-box">
      <div class="title d-flex align-center"><span></span>资料下载</div>
    </div>
    <div class="content">
      <div class="list">
        <div
          class="item d-flex align-center justify-between"
          v-for="child in list"
          :key="child.id"
        >
          <div class="item-l d-flex align-center">
            <img class="item-img" src="../../assets/img/pdf.png" alt="" />
            <div class="item-name d-flex flex-column justify-between">
              {{ child.name }}
              <div class="item-time">
                下载数：{{ child.hits }}
                <!-- <span>下载数：{{ item.hits }}</span> -->
              </div>
            </div>
          </div>
          <div class="item-r d-flex align-center">
            <a
              class="item-btn d-flex align-center justify-center cursor"
              :href="url +'/'+ child.fileurl"
            >
              下载
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../utils/config";
export default {
  data() {
    return {
      list: "",
       url: config.baseURL
    };
  },
  mounted() {
    this.list = JSON.parse(window.localStorage.getItem("documentsList"));
  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.title-box {
  width: 1000px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 25px 36px 25px 36px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #444444;
    span {
      width: 4px;
      height: 24px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 12px;
      display: inline-block;
    }
  }
}
.content {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  min-height: 546px;
  .list {
    padding: 26px 36px;
    box-sizing: border-box;
    .item {
      height: 96px;
      background: #ffffff;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f9ff;
      .item-l {
        .item-img {
          height: 55px;
          width: 45px;
          margin-right: 20px;
        }
        .item-name {
          font-size: 18px;
          color: #333333;
          height: 55px;
          .item-time {
            color: #999999;
            font-size: 14px;
            span {
              margin-left: 23px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
      .item-r {
        .download {
          font-size: 16px;
          color: #999999;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .study {
          // margin-left: auto;
          margin-right: 20px;
          color: #5f2eff;
        }
        .study-w {
          // margin-left: auto;
          margin-right: 20px;
          color: #999999;
        }
        .item-btn {
          width: 104px;
          height: 40px;
          background: #f3efff;
          border: 1px solid #5f2eff;
          border-radius: 20px;
          font-size: 16px;
          color: #5f2eff;
        }
      }
    }
  }
  .lists {
    padding: 26px 36px;
    box-sizing: border-box;
    max-height: 650px;
    overflow-x: scroll;
  }
  .first {
    height: 76px;
    border-bottom: 1px solid #f6f9ff;
    box-sizing: border-box;
    .first-img {
      width: 20px;
      height: 11px;
      margin-right: 10px;
      transition: 0.35s;
      -moz-transition: 0.35s;
      -webkit-transition: 0.35s;
      -o-transition: 0.35s;
    }
    .firit-name {
      font-size: 18px;
      color: #333333;
    }

    .num {
      font-size: 16px;
      color: #999999;
      width: 100px;
      text-align: center;
      margin-right: 16px;
      margin-left: auto;
    }
    .btn {
      height: 32px;
      width: 80px;
      font-size: 16px;
      color: #ffffff;
      margin-right: 7px;
    }
  }

  .item {
    height: 96px;

    background: #ffffff;
    box-sizing: border-box;
    border-bottom: 1px solid #f6f9ff;
    .item-l {
      .item-img {
        height: 55px;
        width: 45px;
        margin-right: 20px;
      }
      .item-name {
        font-size: 18px;
        color: #333333;
        height: 55px;
        .item-time {
          color: #999999;
          font-size: 14px;
          span {
            margin-left: 23px;
            color: #999999;
            font-size: 14px;
          }
        }
      }
    }
    .item-r {
      .download {
        font-size: 16px;
        color: #999999;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .study {
        // margin-left: auto;
        margin-right: 20px;
        color: #5f2eff;
      }
      .study-w {
        // margin-left: auto;
        margin-right: 20px;
        color: #999999;
      }
      .item-btn {
        width: 104px;
        height: 40px;
        background: #f3efff;
        border: 1px solid #5f2eff;
        border-radius: 20px;
        font-size: 16px;
        color: #5f2eff;
      }
    }
  }
}
</style>
